import React from 'react';

import '../../styles/common/ContactBox.css';

function ContactBox({msg, placeHolder, link, icon}) {
    let linkHref = null;

    if (link.includes('@'))
        linkHref = `mailto:${link}`;
    else if (link.includes('+33'))
        linkHref = `tel:${link}`;
    else
        linkHref = `${link}`;

    return (
        <div className="contact-box">
            <div style={{margin: '30px 30px 0px 30px'}}>
                {placeHolder === null ? <a href={linkHref} target="_blank" rel="noreferrer">{icon}</a> : <a href={linkHref}>{icon}</a>}
            </div>
                <p>{msg}</p>
                {placeHolder ? <a href={linkHref}>{placeHolder}</a> : null}
        </div>
    )
}

export default ContactBox;
