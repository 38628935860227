import 'styles/pages/Competences.css';

import { Lang } from 'data/Lang';
import { competenceList } from 'data/CompetencesList';

import Title from 'components/common/Title';
import CompetenceSection from 'components/common/CompetenceSection';

function Competences({englishMode}) {
    let content;
    let skills;

    if (englishMode) {
        content = Lang.en.competence;
        skills = competenceList.en;
    } else {
        content = Lang.fr.competence;
        skills = competenceList.fr;
    }

    const title = content.title;
	return (
        <div id='competences'>
            <div className='competences-container'>
                <Title title={title} darkColor={true} />
            </div>
            <div className='competence-section-container'>
                {
                    skills.map((skill, index) => {
                        return (
                            <CompetenceSection key={index} skill={skill} isRight={index % 2 ? true : false} />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Competences