import 'styles/pages/Projects.css';

import { ProjectsList } from 'data/ProjectsList';
import { Lang } from 'data/Lang';

import Title from 'components/common/Title';
import ProjectSection from 'components/common/ProjectSection';

function Projects({englishMode}) {
    let content;
    let projects;

    if (englishMode) {
        content = Lang.en.projects;
        projects = ProjectsList.en;
    } else {
        content = Lang.fr.projects;
        projects = ProjectsList.fr;
    }

    const title = content.title;
	return (
        <div id='projects'>
            <div>
                <Title title={title} darkColor={true} />
            </div>
            <div className='projects-container'>
                {
                    projects.map((project, index) => {
                        return (
                            <ProjectSection key={index} project={project}/>
                        )
                    })
                }
            </div>
        </div>
    )
}


export default Projects