
import frontIcon from 'assets/icons/competences/frontIcon.svg'
import backIcon from 'assets/icons/competences/backIcon.svg'
import integrationIcon from 'assets/icons/competences/integrationIcon.svg'
import businessLogicIcon from 'assets/icons/competences/businessLogicIcon.svg'
import workspaceIcon from 'assets/icons/competences/workspaceIcon.svg'
import hobbyIcon from 'assets/icons/competences/hobbyIcon.svg'

export const competenceList = {
    fr: [
        {
            title: 'Front-End',
            description: 'Création et intégration de sites web et d\'applications mobiles responsive.',
            skills: [
                'HTML',
                'CSS',
                'JavaScript',
                'TypeScript',
                'Less',
                'Jquery',
                'VueJS',
                'ReactJS',
                'React Native',
                'Java Android',
                'Jest (JavaScript)',
                'Regex',
            ],
            cover: frontIcon,
            color: '#639DE1'
        },
        {
            title: 'Back-End',
            description: 'Développement de sites web et applications, création et gestion de bases de données, programmation orientée objet.',
            skills: [
                'NodeJS',
                'PHP',
                'Python',
                'JAVA',
                'C / C++',
                'Bash',
                'Electron',
                'PyTest (Python)',
                'Google-test (C++)',
                'JUnit (Java)',
                'MySQL',
                'PhpMyAdmin',
                'PostgreSQL',
                'Apache',
                'Debian'
            ],
            cover: backIcon,
            color: '#F04D4D'
        },
        {
            title: 'Integration',
            description: 'Intégration & déployement de projet au travers de divers bonnes pratiques techniques.',
            skills: [
                'Git',
                'GitLab / GitHub',
                'CI/CD',
                'Docker',
                'Kubernetes',
                'NPM',
            ],
            cover: integrationIcon,
            color: '#8941E4'
        },
        {
            title: 'Autres Compétences',
            description: '',
            skills: [
                'Anglais parlé & écrit',
                'Machine Learning: Keras / TensorFlow',
                'Conception d\'API Rest',
                'Systeme d\'Authentification: JWT Token / OAuth2',
                'Maintenance de serveur: sauvegarde planifiée'
            ],
            cover: businessLogicIcon,
            color: '#6BE757'
        },
        {
            title: 'Environnement de Travail',
            description: 'Outils et logiciels maitrisés',
            skills: [
                'Linux',
                'Mac',
                'Windows 10',
                'FileZilla',
                'GitKraken',
                'Trello',
                'Slack',
                'Postman',
                'Figma',
                'Visual Studio Code',
                'JetBrains IDE',
                'Pack Office 365',
            ],
            cover: workspaceIcon,
            color: '#868B89'
        },
        {
            title: 'Loisirs',
            description: 'Activités que j\'aime pratiquer durant mon temps libre.',
            skills: [
                'Voyages',
                'Sorties',
                'Musique',
                'Sport de Combat (Karaté 8 ans)',
                'Footing',
                'Natation',
                'Cuisine',
                'Projets personnels'
            ],
            cover: hobbyIcon,
            color: '#0F9A92'
        }
    ],
    en: [
        {
            title: 'Front-End',
            description: 'Design and build of responsive websites and mobile applications.',
            skills: [
                'HTML',
                'CSS',
                'JavaScript',
                'TypeScript',
                'Less',
                'Jquery',
                'VueJS',
                'ReactJS',
                'React Native',
                'Java Android',
                'Jest (JavaScript)',
                'Regex',
            ],
            cover: frontIcon,
            color: '#639DE1'
        },
        {
            title: 'Back-End',
            description: 'Development of websites & applications back-end, databases management, object-oriented programming.',
            skills: [
                'NodeJS',
                'PHP',
                'Python',
                'JAVA',
                'C / C++',
                'Bash',
                'Electron',
                'PyTest (Python)',
                'Google-test (C++)',
                'JUnit (Java)',
                'MySQL',
                'PhpMyAdmin',
                'PostgreSQL',
                'Apache',
                'Debian'
            ],
            cover: backIcon,
            color: '#F04D4D'
        },
        {
            title: 'Integration',
            description: 'Integration and deployment of projects through various technical practices.',
            skills: [
                'Git',
                'GitLab / GitHub',
                'CI/CD',
                'Docker',
                'Kubernetes',
                'NPM',
            ],
            cover: integrationIcon,
            color: '#8941E4'
        },
        {
            title: 'Other skills',
            description: '',
            skills: [
                'English spoken and written',
                'Machine Learning: Keras / TensorFlow',
                'API Design & Integration',
                'Authentication System: JWT Token / OAuth2',
                'Server maintenance'
            ],
            cover: businessLogicIcon,
            color: '#6BE757'
        },
        {
            title: 'Working environment',
            description: 'Mastered tools and software',
            skills: [
                'Linux',
                'Mac',
                'Windows 10',
                'FileZilla',
                'GitKraken',
                'Trello',
                'Slack',
                'Postman',
                'Figma',
                'Visual Studio Code',
                'JetBrains IDE',
                'Pack Office 365',
            ],
            cover: workspaceIcon,
            color: '#868B89'
        },
        {
            title: 'Hobbies',
            description: 'Activities that I like to practice during my free time.',
            skills: [
                'Travel',
                'Go out',
                'Music',
                'Combat Sport (Karate 8 years old)',
                'Jogging',
                'Swimming',
                'Cooking',
                'Personal IT projects'
            ],
            cover: hobbyIcon,
            color: '#0F9A92'
        }
    ]

}