import * as React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import 'styles/pages/Experience.css'

import { Lang } from 'data/Lang';
import { ExperiencesList } from 'data/ExperiencesList';

import Title from 'components/common/Title'

function Experience({englishMode}) {
    let content;
    let experiences;

    if (englishMode) {
        content = Lang.en.experience;
        experiences = ExperiencesList.en;
    } else {
        content = Lang.fr.experience;
        experiences = ExperiencesList.fr;
    }

    const title = content.title;

    return (
        <div id='experience'>
            <div className='experience-container'>
                <Title title={title} darkColor={false}/>
            </div>
            <div className='experience-section-container'>
                <VerticalTimeline>
                    {
                    experiences.map((competence, index) => {
                        let Icon = competence.icon;
                        let subtitle;
                        let yearPosition = index % 2 === 0 ? 'right' : 'left';
                        let logoPosition = index % 2 !== 0 ? 'right' : 'left';
                        if (competence.type === 'Job')
                            subtitle = competence.entreprise;
                        else
                            subtitle = competence.diplome;
                        return (
                            <VerticalTimelineElement
                                key={index}
                                iconStyle={{ background: competence.color, color: '#fff' }}
                                icon={<Icon />}
                            >
                                <h3 className="experience-title">{competence.title}</h3>
                                <h4 className="experience-subtitle">{subtitle}</h4>
                                <div className="experience-stack-list" >
                                    {
                                        competence.stack !== undefined ? competence.stack.map((tech, techIndex) => {return (<div key={techIndex} className="experience-stack">{tech}</div>)}) : null
                                    }
                                </div>
                                {
                                    Array.isArray(competence.description) ?
                                    <ul className="experience-desc-list">
                                        {competence.description.map((lesson, lessonIndex) => {return (<li key={lessonIndex}>{lesson}</li>)} )}
                                    </ul>
                                    :
                                    <p className="experience-desc">{competence.description}</p> 
                                }
                                { competence.logo ? <img className='experience-logo' src={competence.logo} alt={competence.title} style={{float: logoPosition}}/> : null }
                                <span className="experience-date" style={{float: yearPosition}}>{competence.date}</span>
                            </VerticalTimelineElement>
                        )
                    })
                    }
                </VerticalTimeline>
            </div>
        </div>
    )
}

export default Experience;