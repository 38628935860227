import 'styles/common/CompetenceSection.css'

function CompetenceSection({ skill, isRight }) {

    return (
        <div className="competence-section">
            <div className={`competence-text competence-style ${isRight ? 'second-item' : ''}`}>
                <h3 style={{ color: `${skill.color}`}}>{skill.title}</h3>
                <div className="skills-container">
                    <div className="skill-description">{skill.description}</div>
                    <div className="skill-content">
                        <ul className={` ${skill.skills.length > 6 ? `two-columns` : ''} `}>
                        {
                            skill.skills.map((item, index) => {
                                return (
                                    <li key={index}>{item}</li>
                                )
                            })
                        }
                        </ul>
                    </div>
                </div>
            </div>
            <div className="competence-logo competence-style">
                <div className="logo">
                    <img src={skill.cover} alt={skill.title} />
                </div>
            </div>
        </div>
    )
}

export default CompetenceSection