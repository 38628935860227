import 'styles/App.css';
import 'styles/index.css';
import 'styles/MediaQueries.css';

import React, { useState } from 'react';

import Home from 'components/pages/Home';
import About from 'components/pages/About';
import Competences from 'components/pages/Competences';
import Experience from 'components/pages/Experience';
import Projects from 'components/pages/Projects';
import Contact from 'components/pages/Contact';
import Header from 'components/pages/Header';
 
function App() {
  const [info, setInfo] = useState(false);

  return (
    <div className="App">
      <Header englishMode={info} setEnglishMode={setInfo} />
      <Home englishMode={info} />
      <About englishMode={info} />
      <Competences englishMode={info} />
      <Experience englishMode={info} />
      <Projects englishMode={info} />
      <Contact englishMode={info} />
    </div>
  );
}

export default App;
