import React,{ useState } from 'react';

import { Lang } from 'data/Lang';
import EnLogo from '../../assets/logos/lang/en_flag.png';
import FrLogo from '../../assets/logos/lang/fr_flag.png';

import 'styles/Header.css'

function Header({englishMode, setEnglishMode}) {
    const [isNavBarToggled, setIsNavBarToggled] = useState(false);

    let content;

    if (englishMode)
        content = Lang.en.header;
    else
        content = Lang.fr.header;

    function mobileMenu() {
        setIsNavBarToggled(!isNavBarToggled);
    }

    function switchLang() {
        setEnglishMode(!englishMode);
    }

	return (
        <nav className='nav-bar'>
            <div className='nav-lang'>
                {
                    englishMode ? 
                        <img className='nav-langIcon' src={EnLogo} alt='English Mode' onClick={() => switchLang(englishMode)} /> 
                    : 
                        <img className='nav-langIcon' src={FrLogo} alt='French Mode' onClick={() => switchLang(englishMode)} /> 
                }
            </div>
            <ul className={`nav-menu ${isNavBarToggled ? "active" : ""}`}>
                <li className="nav-item">
                    <a href='#about' onClick={() => setIsNavBarToggled(false)}>{content.aboutTitle}</a>
                </li>
                <li className="nav-item">
                    <a href='#competences' onClick={() => setIsNavBarToggled(false)}>{content.competencesTitle}</a>
                </li>
                <li className="nav-item">
                    <a href='#experience' onClick={() => setIsNavBarToggled(false)}>{content.experienceTitle}</a>
                </li>
                <li className="nav-item">
                    <a href='#projects' onClick={() => setIsNavBarToggled(false)}>{content.projectsTitle}</a>
                </li>
                <li className="nav-item">
                    <a href='#contact' onClick={() => setIsNavBarToggled(false)}>{content.contactTitle}</a>
                </li>
            </ul>            
            <div className={`hamburger ${isNavBarToggled ? "active" : ""}`} onClick={() => mobileMenu()}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </div>
        </nav>
    )
}


export default Header