import 'styles/common/Title.css'

function Title({ title, darkColor }) {
	return (
        <h2 id='title' className={darkColor ? `title-dark-color` : `title-white-color`}>
            {title}
        </h2>
    )
}

export default Title;