import CV_EN from '../assets/cv/en/Resume_Sufyan_Kerboua-2022.pdf'
import CV_FR from '../assets/cv/fr/Resume_Sufyan_Kerboua-2022.pdf'

export const Lang = {
  en: {
    header: {
      aboutTitle: 'About',
      competencesTitle: 'Skills',
      experienceTitle: 'Experience',
      projectsTitle: 'Projects',
      contactTitle: 'Contact',
    },
    home: {
      jobTitle: 'Full stack developer',
      aboutBtn: 'About me',
      resumeBtn: 'My Resume',
      cv: CV_EN,
    },
    about: {
      name: 'Sufyân Kerboua',
      old: ' years old',
      drive: 'Driving License',
      title: 'About Me',
      resume: [
        `My name is Sufyan Kerboua, recently been graduated with my master’s degree in Computer Science from {EPITECH} Paris.`,
        `I am currently looking for an abroad full-time job.`,
        `I love working on various challenging projects.`,
        // `And my next challenge in mind is to work abroad to discover new cultures & professional skills.`,
      ],
    },
    competence: {
      title: 'Skills',
    },
    experience: {
      title: 'Experience',
    },
    projects: {
      title: 'Projects',
    },
    contact: {
      title: 'Contact',
      searchMsg:
        'Currently looking for a new job, I am interted by the roles of :',
      searchFields:
        'Software Engineer • Web Full-Stack developer • Mobile developer',
      mail: 'Send me an e-mail at',
      call: 'Call me at',
    },
  },
  fr: {
    header: {
      aboutTitle: 'A Propos',
      competencesTitle: 'Compétences',
      experienceTitle: 'Parcour',
      projectsTitle: 'Projets',
      contactTitle: 'Contact',
    },
    home: {
      jobTitle: 'Développer full-stack',
      aboutBtn: 'En savoir +',
      resumeBtn: 'Mon CV',
      cv: CV_FR,
    },
    about: {
      name: 'Sufyân Kerboua',
      old: ' ans',
      drive: 'Permis de conduire',
      title: 'À Propos de moi',
      resume: [
        `Je m'appel Sufyân Kerboua, récemment diplômé de mon master en informatique à {EPITECH} Paris.`,
        `Je suis actuellement à la recherche d'un emploi à temps plein.`,
        `J'aime travailler sur divers projets stimulants`,
        // `Et mon prochain défi en tête est de travailler à l'étranger pour découvrir de nouvelles cultures ainsi que de nouvelles compétences professionnelles.`,
      ],
    },
    competence: {
      title: 'Compétences',
    },
    experience: {
      title: 'Parcour',
    },
    projects: {
      title: 'Projets',
    },
    contact: {
      title: 'Contactez-Moi',
      searchMsg: `Actuellement à la recherche d'un nouvel emploi, je suis intéressée par les postes de :`,
      searchFields:
        'Ingénieur logiciel • Développeur Full-Stack Web • Développeur Mobile',
      mail: 'Envoyez-moi un e-mail à',
      call: 'Appelez-moi au',
    },
  },
}
