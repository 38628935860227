import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';

import PricingLogo from '../assets/logos/companies/pricing.png';
import GardePeriscolaireLogo from '../assets/logos/companies/GardePeriscolaire.png';
import TwoSevenLabLogo from '../assets/logos/companies/TwoSevenLab.png';
import SuricatConsultingLogo from '../assets/logos/companies/SuricatConsulting.png';
import DevoteamLogo from '../assets/logos/companies/Devoteam.png';

import BerliozLogo from '../assets/logos/schools/berlioz.png';
import EpitechLogo from '../assets/logos/schools/epitech.png';
import DCULogo from '../assets/logos/schools/dcu.png';
import UHALogo from '../assets/logos/schools/uha.png';

import NeuratioLogo from '../assets/logos/schools/neuratio.png';

export const ExperiencesList = {
    fr: [
        {
            type: 'Formation',
            date: '2016 - 2021',
            title: 'Epitech',
            diplome: 'Expert en Technologie de l\'information',
            description: ['Unix', 'GIT', 'Programmation Orienté Objets', 'Algorithmique', 'Réseau', 'Developpement Mobile', 'Conteneurisation', 'Application Web', 'Web Security', 'IA'],
            color: '#1a5cc7',
            icon: SchoolIcon,
            logo: EpitechLogo
        },
        {
            type: 'Job',
            date: '2021',
            title: 'Architecte API',
            duration: '6 mois',
            contract: 'Stage',
            entreprise: 'Devoteam Nexdigital',
            description: 'Mise en place d’un système de tableau d’affichage, de données de mobilité et de transport en temps réel (API Management, Microservices, Open Data).',
            stack: ["MuleSoft", "ReactJS", "JS", "HTML", "CSS", "MongoDB"],
            color: '#038E22',
            icon: WorkIcon,
            logo: DevoteamLogo
        },
        {
            type: 'project',
            date: '2019 - 2021',
            title: 'Epitech Innovative Project - EIP',
            diplome: 'Neuratio',
            description: 'L’EIP (Epitech Innovation Project) est mon projet de fin d’études dans un groupe de 9, s’écoulant sur 3 ans, via une méthode scrum. Neuratio vise à aider les patients incapables de communiquer, via un casque neuronal permettant de capter les signaux neuromusculaires, en les interprétants, au travers d’une interface graphique.',
            stack: ['C++', 'Boost Asio', 'Python', 'Keras', 'Machine Learning', 'PostgreSQL', 'Docker'],
            color: '#1a5cc7',
            icon: SchoolIcon,
            logo: NeuratioLogo
        },
        {
            type: 'Job',
            date: '2020 - 2021',
            title: 'Consultant Informatique',
            duration: '6 mois',
            contract: 'Stage Alterné',
            entreprise: 'Suricat Consulting',
            description: 'Accompagnement / développement de différents projets aussi bien en interne qu’en externe sur divers sujets (IA, API, Architecture). Mise en place d’un algorithme de reconnaissance d’images de différentes plantes',
            stack: ["Python", "TensorFlow", "Keras", "WordPress", "Drupal 8"],
            color: '#038E22',
            icon: WorkIcon,
            logo: SuricatConsultingLogo
        },
        {
            type: 'Formation',
            date: '2020 - 2021',
            title: 'Université Haute Alsace - UHA',
            diplome: 'Master 2 en Management de l\'innovation',
            description: ['Financement & Entrepreunariat', 'Marketing', 'Gestion de Projet'],
            color: '#1a5cc7',
            icon: SchoolIcon,
            logo: UHALogo
        },
        {
            type: 'Formation',
            date: '2019 - 2020',
            title: 'Dublin City University - DCU',
            diplome: 'test',
            description: ['IT architecture', 'Application Web', 'Tests de logiciels', 'Base du Management de Projet', 'Machine Learning', 'Cloud Computing', 'Méthodologique en ingénierie logicielle'],
            color: '#1a5cc7',
            icon: SchoolIcon,
            logo: DCULogo
        },
        {
            type: 'Job',
            date: '2019',
            title: 'Développeur Logiciel',
            duration: '5 mois',
            contract: 'Stage',
            entreprise: 'TwoSevenLab',
            description: 'Création de plugins destinés à la suite Adobe (After Effect / Premiere Pro) permettant d’aider des graphistes / monteurs de vidéos. Création d’un environnement permettant l’utilisation / installation automatique de police d’écriture au sein de l’entreprise.',
            stack: ["NodeJS", "JS", "JQuery", "VueJS", "HTML", "CSS"],
            color: '#038E22',
            icon: WorkIcon,
            logo: TwoSevenLabLogo
        },
        {
            type: 'Job',
            date: '2018 - 2019',
            title: 'Développeur Full-stack',
            duration: '6 mois',
            contract: 'Stage Alterné',
            entreprise: 'Garde Periscolaire',
            description: 'Administration de l’intranet d’une entreprise, rajout de fonctionnalités (Signature électronique, devis automatique ... ). Maintenance de serveur (sauvegarde automatique, sécurisation ...)',
            stack: ["NodeJS", "JS", "JQuery", "VueJS", "HTML", "CSS"],
            color: '#038E22',
            icon: WorkIcon,
            logo: GardePeriscolaireLogo
        },
        {
            type: 'Job',
            date: '2017',
            title: 'Extracteur de données Web',
            duration: '4 mois',
            contract: 'Stage',
            entreprise: 'Pricing Assistant',
            description: 'Récupérer des données sur des sites d’e-commerçant.',
            stack: ["Python", "Regex", "XPATH", "HTML", "CSS"],
            color: '#038E22',
            icon: WorkIcon,
            logo: PricingLogo
        },
        {
            type: 'Formation',
            date: '2016',
            title: 'Obtention BACCALAUREAT',
            diplome: 'Général Section Scientifique',
            color: '#1a5cc7',
            icon: SchoolIcon,
            logo: BerliozLogo
        }
    ],
    en: [
        {
            type: 'Formation',
            date: '2016 - 2021',
            title: 'Epitech',
            diplome: 'Information Technology Expert',
            description: ['Unix', 'GIT', 'Object Oriented Programming', 'Algorithmic', 'Network', 'Mobile development', 'Containerization', 'Web application', 'Web Security', 'AI'],
            color: '#1a5cc7',
            icon: SchoolIcon,
            logo: EpitechLogo
        },
        {
            type: 'Job',
            date: '2021',
            title: 'API architect',
            duration: '6 months',
            contract: 'Traineeship',
            entreprise: 'Devoteam Nexdigital',
            description: 'Implementation of a real-time mobility billboard system (API Management, Microservices, Open Data).',
            stack: ["MuleSoft", "ReactJS", "JS", "HTML", "CSS", "MongoDB", "Figma"],
            color: '#038E22',
            icon: WorkIcon,
            logo: DevoteamLogo
        },
        {
            type: 'project',
            date: '2019 - 2021',
            title: 'Epitech Innovative Project - EIP',
            diplome: 'Neuratio',
            description: 'The EIP (Epitech Innovation Project) is my end of studies project in a group of 9, running over 3 years, via a scrum method. The aims of Neuratio project is to help unable patients to communicate, thanks to a neural helmet allowing to catch neuromuscular signals, interpreting them, and finally show them through a graphical interface.',
            stack: ['C++', 'Boost Asio', 'Python', 'Keras', 'Machine Learning', 'PostgreSQL', 'Docker'],
            color: '#1a5cc7',
            icon: SchoolIcon,
            logo: NeuratioLogo
        },
        {
            type: 'Job',
            date: '2020 - 2021',
            title: 'IT consultant',
            duration: '6 months',
            contract: 'Part-time internship',
            entreprise: 'Suricat Consulting',
            description: 'Coaching & development of different projects both internally and externally on various subjects (AI, API, Architecture). Implementation of an image recognition algorithm for various plants',
            stack: ["Python", "TensorFlow", "Keras", "WordPress", "Drupal 8"],
            color: '#038E22',
            icon: WorkIcon,
            logo: SuricatConsultingLogo
        },
        {
            type: 'Formation',
            date: '2020 - 2021',
            title: 'Université Haute Alsace - UHA',
            diplome: 'Master 2 in Innovation & Project Management',
            description: ['Funding & Entrepreneurship', 'Marketing', 'Project management'],
            color: '#1a5cc7',
            icon: SchoolIcon,
            logo: UHALogo
        },
        {
            type: 'Formation',
            date: '2019 - 2020',
            title: 'Dublin City University - DCU',
            diplome: 'Engineering Computing',
            description: ['IT architecture', 'Web Application', 'Software testing', 'Basis of Project Management', 'Machine Learning', 'Cloud Computing', 'Methodology in software engineering'],
            color: '#1a5cc7',
            icon: SchoolIcon,
            logo: DCULogo
        },
        {
            type: 'Job',
            date: '2019',
            title: 'Software developer',
            duration: '5 months',
            contract: 'Internship',
            entreprise: 'TwoSevenLab',
            description: 'Creation of plugins for the Adobe Suite (After Effect/Premiere Pro) to help graphic designers/video editors. Creation of an environment allowing the automatic use/installation of fonts within the company.',
            stack: ["NodeJS", "JS", "JQuery", "VueJS", "HTML", "CSS"],
            color: '#038E22',
            icon: WorkIcon,
            logo: TwoSevenLabLogo
        },
        {
            type: 'Job',
            date: '2018 - 2019',
            title: 'Full-stack developer',
            duration: '6 months',
            contract: 'Part-time internship',
            entreprise: 'Garde Periscolaire',
            description: 'Administration of a company\'s intranet, functionalities addition (electronic signature, automatic quote, etc.). Server maintenance (automatic backup, security, etc.)',
            stack: ["NodeJS", "JS", "JQuery", "VueJS", "HTML", "CSS"],
            color: '#038E22',
            icon: WorkIcon,
            logo: GardePeriscolaireLogo
        },
        {
            type: 'Job',
            date: '2017',
            title: 'Web Data Extractor',
            duration: '4 months',
            contract: 'StaInternshipge',
            entreprise: 'Pricing Assistant',
            description: 'Data extraction from E-commercial websites.',
            stack: ["Python", "Regex", "XPATH", "HTML", "CSS"],
            color: '#038E22',
            icon: WorkIcon,
            logo: PricingLogo
        },
        {
            type: 'Formation',
            date: '2016',
            title: 'BACCALAURATE',
            diplome: 'Scientific Section',
            color: '#1a5cc7',
            icon: SchoolIcon,
            logo: BerliozLogo
        }
    ]
};

