import 'styles/pages/About.css';
import profilePicture from 'assets/profil_picture.jpg';
import { Lang } from 'data/Lang';

function About({englishMode}) {
    let content;

    if (englishMode)
        content = Lang.en.about;
    else
        content = Lang.fr.about;
    
    const dateOne = new Date();
    const dateTwo = new Date("09/12/1998");

    const dateOneUTC = Date.UTC(dateOne.getFullYear(), dateOne.getMonth(), dateOne.getDate());
    const dateTwoUTC = Date.UTC(dateTwo.getFullYear(), dateTwo.getMonth(), dateTwo.getDate());

    const difference = dateOneUTC - dateTwoUTC;
    const age = Math.floor(difference / (1000 * 60 * 60* 24 * 365.22));

	return (
        <div id='about'>
            <div className='about-container'>
                <div className='about-section'>
                    <img className='about-pp' src={profilePicture} alt='Profil'/>
                    <div className='about-basic-information'>
                        <p>{`${content.name}`}</p>
                        <p>{`${age} ${content.old}`}</p>
                        <p>{`${content.drive}`}</p>
                    </div>
                </div>
                <div className='about-section'>
                    <div className='about-text-section'>
                        <h3>{content.title}</h3>
                        {
                            content.resume.map((line, lineIndex) => {
                                return (<p key={lineIndex}>{line}</p>)
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About