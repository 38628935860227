export const ProjectsList = {
    fr: [
        {
            name: 'Neuratio',
            image: 'https://img.youtube.com/vi/Qfddu3JXceM/maxresdefault.jpg',
            video_url: 'Qfddu3JXceM',
            desc: ["L’EIP (Epitech Innovation Project) est mon projet de fin d’études dans un groupe de 9, s’écoulant sur 3 ans, via une méthode scrum.", "Neuratio vise à aider les patients incapables de communiquer, via un casque neuronal permettant de capter les signaux neuromusculaires, en les interprétants, au travers d’une interface graphique."],
            technos: ['C++', 'Boost Asio', 'Python', 'Keras', 'Machine Learning', 'PostgreSQL', 'Docker']
        },
        {
            name: 'Secret Santa Clause',
            image: 'http://i3.ytimg.com/vi/lTjW9etOY0A/maxresdefault.jpg',
            video_url: 'lTjW9etOY0A',
            desc: ["L’objectif de ce projet personnel est de pouvoir désigner aléatoirement des participants pour un Secret Santa, via une application desktop.", "L’application permet d’envoyer des mails d’assignation entre chaque participant, via une authentification Gmail (OAuth2).", "L’assignation se fait de manière aléatoire mais contrôlé, de telle sorte à ce que personne n’envoie un cadeau à la même personne qu’une année précédente."],
            technos: ["Electron", "NodeJS", "VueJS"]
        },
        {
            name: 'Eat At DCU',
            image: 'http://i3.ytimg.com/vi/_UIZBbQ70ZQ/maxresdefault.jpg',
            video_url: '_UIZBbQ70ZQ',
            desc: ["EatAtDCU est un projet d’école ayant pour objectif d’aider un employé à trouver un restaurant au sein de l’un des campus disponibles à DCU.", "Cette application web communique via l’API de l’école, elle permet de facilement consulter les repas actuellement disponibles parmi les différents campus de l’école."],
            technos: ["Python", "Django", "SQLite", "VueJS", "Bootstrap"]
        },
        {
            name: 'OpenFoodFact',
            image: 'http://i3.ytimg.com/vi/5ZX718JnuoA/maxresdefault.jpg',
            video_url: '5ZX718JnuoA',
            desc: [`Développer une application en mode webApp permettant à un utilisateur de se connecter, ainsi que de rajouter/supprimer de produits le tout via leurs codes barres.`,
                    `Les informations concerant ces produits sont généré via l'API OpenFoodFact.`,
                    `L'application comporte 3 pages principales.`,
                    `La première permet à un utilisateur de se créer/connecter via un nom d'utilisateur ainsi que d'un mot de passe.`,
                    `La seconde page, permet à un utilisateur connecté de consulter un dashboard de tout les produits que celui-ci à deja pu consulter, avec la possibilité de rajouter un produit via un code barre.`,
                    `Enfin la dernière, permet à l'utilisateur de modifier les informations de son profil, de supprimer son compte, ou encore de supprimer tous les produits consultés.`],
            technos: ["ReactJS", "JavaScript", "NestJS", "MongoDB", "Material-ui"]
        },
        {
            name: 'Epicture',
            image: 'http://i3.ytimg.com/vi/LEt5JBr2-OE/hqdefault.jpg',
            video_url: 'LEt5JBr2-OE',
            desc: ["Epicture est un projet à réaliser durant ma 3eme année à {EPITECH}. L'objectif du projet était de créer un gestionnaire de partage de photo en ligne via une l'API d'Imgur.",
                    "Le projet Epicture possède :",
                    "Une connection 0Auth2, vers l'application Imgur",
                    "Des calls à l'API Imgur",
                    "Une map illimitée"],
            technos: ["JAVA", "Android Studio"]
        },
        {
            name: 'Bomberman',
            image: 'http://i3.ytimg.com/vi/KkgiyOAEJg0/maxresdefault.jpg',
            video_url: 'KkgiyOAEJg0',
            desc: ["Le projet BomberMan, est un projet de groupe réalisé durant ma 2eme année à {EPITECH} Paris.",
                    "L’objectif était de reproduire le jeu de plateforme BomberMan, avec au choix des joueurs ou des IA.",
                    "Le tout avec des models 3D utilisés via la librairie graphique \"irrlicht\".",
                    "Le projet BomberMan possède:",
                    "L'utilisation de modèle 3D",
                    "Customization des touches",
                    "Customisation de la map"],
            technos: ["C++", "Irrlicht"]
        },
        {
            name: 'Arcade',
            image: 'http://i3.ytimg.com/vi/H5Zjihwj0OY/hqdefault.jpg',
            video_url: 'H5Zjihwj0OY',
            desc: [`Le projet Arcade, est un projet d’école réalisé en groupe, durant ma 2eme année à {EPITECH} Paris.`,
                    `Le but étant de créer un ensemble de jeu de platform, le tout réuni sous forme de plusieurs librairies dynamics. Ce projet à pour but de laisser l’utilisateur choisir un jeux de son choix, tout en gardant son score à jour.`,
                    `Le vrai intérêt de ce projet est cependant, d’être capable d’interagir avec different éléments de notre jeu en temps réel (run-time). Comme par exemple: changer de jeux/librairies pendant l’execution d’un jeu ou du menu de selection.`,
                    `Ainsi que 3 jeux ont été codés : Qix, Snake, Pacman`,
                    `Le projet Zappy possède:`,
                    `3 possibles interfaces graphiques`,
                    `3 possibles jeux disponibles`,
                    `Un systeme de gestionnaire de score pour chaque jeu.`],
            technos: ["C++", "SFML", "SDL", "Ncurse"]
        }
    ],
    en: [
        {
            name: 'Neuratio',
            image: 'https://img.youtube.com/vi/Qfddu3JXceM/maxresdefault.jpg',
            video_url: 'Qfddu3JXceM',
            desc: ["The EIP (Epitech Innovation Project) is my graduation project in a group of 9, during 3 years, through a scrum method. "," Neuratio aims to help patients unable to communicate, thanks to a neural headset allowing to capture neuromuscular signals, interpreting them, through a graphic interface."],
            technos: ['C++', 'Boost Asio', 'Python', 'Keras', 'Machine Learning', 'PostgreSQL', 'Docker']
        },
        {
            name: 'Secret Santa Clause',
            image: 'http://i3.ytimg.com/vi/lTjW9etOY0A/maxresdefault.jpg',
            video_url: 'lTjW9etOY0A',
            desc: ["The objective of this personal project is to be able to randomly designate participants for a Secret Santa, thanks to a desktop application."," The application allows you to send assignment emails between each participant, via Gmail authentication (OAuth2)."," The assignment is random but controlled, in a way that nobody sends a gift to the same person of a previous years."],
            technos: ["Electron", "NodeJS", "VueJS"]
        },
        {
            name: 'Eat At DCU',
            image: 'http://i3.ytimg.com/vi/_UIZBbQ70ZQ/maxresdefault.jpg',
            video_url: '_UIZBbQ70ZQ',
            desc: ["EatAtDCU is a school project aimed at helping an employee find a restaurant in one of the available campuses from DCU.", "This web application communicates via the school's API, it allows to easily consult the meals currently available on the various campuses of the school."],
            technos: ["Python", "Django", "SQLite", "VueJS", "Bootstrap"]
        },
        {
            name: 'OpenFoodFact',
            image: 'http://i3.ytimg.com/vi/5ZX718JnuoA/maxresdefault.jpg',
            video_url: '5ZX718JnuoA',
            desc: [
                `WebApp project allowing a user to connect to a personal dashboard listing food products informations.`,
                `This web application allow to add & remove a product thanks to their bar code.`,
                `The product data are fetched from the OpenFoodFact API.`,
                `This web application is split in 2 part, 1 front-end in ReactJS & 1 back-end in NestJS.`
            ],
            technos: ["ReactJS", "JavaScript", "NestJS", "MongoDB", "Material-ui"]
        },
        {
            name: 'Epicture',
            image: 'http://i3.ytimg.com/vi/LEt5JBr2-OE/hqdefault.jpg',
            video_url: 'LEt5JBr2-OE',
            desc: ["Epicture is a project carried during my 3rd year at {EPITECH}. The objective of this project was to create an online photo manager sharing via the Imgur API.",
                    "The Epicture project has:",
                    "A 0Auth2 connection, to the Imgur application",
                    "Calls to the Imgur API",
                    "An unlimited map"],
            technos: ["JAVA", "Android Studio"]
        },
        {
            name: 'Bomberman',
            image: 'http://i3.ytimg.com/vi/KkgiyOAEJg0/maxresdefault.jpg',
            video_url: 'KkgiyOAEJg0',
            desc: ["The BomberMan project is a group project carried out during my 2nd year at {EPITECH} Paris.",
                    "The objective was to reproduce the platform game BomberMan, with the choice of players or AI.",
                    "All with 3D models used thanks to the \"irrlicht\" graphics library.",
                    "The BomberMan project can:",
                    "Use 3D model",
                    "Customize each touch control",
                    "Customize the map"],
            technos: ["C++", "Irrlicht"]
        },
        {
            name: 'Arcade',
            image: 'http://i3.ytimg.com/vi/H5Zjihwj0OY/hqdefault.jpg',
            video_url: 'H5Zjihwj0OY',
            desc: [`The Arcade project is a school project carried out in a group, during my 2nd year at {EPITECH} Paris.`,
                    `The goal is to create a set of platform games, all gathered in the form of several dynamic libraries. This project aims to let the user choose a game of their choice, while keeping their score up to date.`,
                    `The real interest of this project is to be able to interact with different elements of our game in run-time. Like for example: changing games / libraries while a game is running or from the selection menu.`,
                    `As well as 3 games have been coded: Qix, Snake, Pacman`,
                    `The Arcade project has:`,
                    `3 graphical interfaces`,
                    `3 available games`,
                    `A score manager system for each game.`],
            technos: ["C++", "SFML", "SDL", "Ncurse"]
        }    
    ]
};

