import React from 'react'

function ProjectSection({project}) {
    return (
        <div className="project-section">
            <div className="project-img-box">
                <div className='project-video-container'>
                    <iframe
                        width="-webkit-fill-available"
                        height="-webkit-fill-available"
                        src={`https://www.youtube-nocookie.com/embed/${project.video_url}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title={`Project ${project.name}`}
                    />
                </div>
            </div>
            <div className="project-overlay">
                <h3 className="project-title">{project.name}</h3>
                <div className="project-content">
                    <div className="project-stack-container">
                    {
                        project.technos.map((tech, index) => {return (<span key={index} className="project-stack">{tech}</span>)})
                    }
                    </div>
                    {
                        Array.isArray(project.desc) ?
                            project.desc.map((text, indexTxt) => {
                                return (<p key={indexTxt}>{text}</p>)
                            })
                        : <p>{project.desc}</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default ProjectSection;
