import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

import 'styles/pages/Contact.css';

import Title from 'components/common/Title';
import ContactBox from 'components/common/ContactBox';
import { Lang } from 'data/Lang';

function Contact({englishMode}) {
    let content;

    if (englishMode)
        content = Lang.en.contact;
    else
        content = Lang.fr.contact;

    const title = content.title;
	return (
        <div id='contact'>
            <div>
                <Title title={title} darkColor={true} />
            </div>
            <div className='contact-container'>
                <p>{content.searchMsg}</p>
                <p>{content.searchFields}</p>

                <div style={{display: 'flex', margin: '0px 50px', flexFlow: 'row wrap'}}>
                    <ContactBox msg={content.mail} placeHolder={`sufyan.kerboua@epitech.eu`} link={`sufyan.kerboua@epitech.eu`} icon={<EmailIcon className='contact-icon' sx={{ fontSize: 80, color: '#f16063' }}/>} />
                    <ContactBox msg={content.call} placeHolder={`+33 6 50 45 56 79`} link={`+330650455679`} icon={<PhoneIcon className='contact-icon' sx={{ fontSize: 80, color: '#25D366' }}/>} />
                    <ContactBox msg={`Linkedin`} placeHolder={null} link={`https://www.linkedin.com/in/sufyan-kerboua/`} icon={<LinkedInIcon className='contact-icon' sx={{ fontSize: 80, color: '#0a66c2' }}/>} />
                    <ContactBox msg={`Github`} placeHolder={null} link={`https://www.github.com/SufyanKerboua/`} icon={<GitHubIcon className='contact-icon' sx={{ fontSize: 80, color: '#000000' }}/>} />
                </div>
            </div>
        </div>
    )
}


export default Contact