import { Lang } from 'data/Lang';

import 'styles/pages/Home.css';

function Home({englishMode}) {
    let content;

    if (englishMode)
        content = Lang.en.home;
    else
        content = Lang.fr.home;
	return (
        <div className='home-container'>
            <div className='home-background'>
                <div className='home-profil-container'>
                    <h1 className='home-name'>Sufyân Kerboua</h1>
                    <hr />
                    <h1 className='home-job-title'>{content.jobTitle}</h1>
                </div>
                <a href='#about'><button className='home-btn'>{content.aboutBtn}</button></a>
                <a href={content.cv} target='_blank' rel="noreferrer"><button className='home-btn'>{content.resumeBtn}</button></a>
            </div>
        </div>
    )
}

export default Home;